import React from 'react'
import Moment from 'react-moment'
import 'moment/locale/it'

const ArticleInfo = ({ date, commentsLength }) => (
  <span className="u-text--desc mt-3 mb-2">
    <Moment locale="it" date={date} fromNow />
    {commentsLength ? (
      <span>
        {' '}
        | {commentsLength} COMMENT{commentsLength === 1 ? 'O' : 'I'}
      </span>
    ) : (
      ''
    )}
  </span>
)

export default ArticleInfo
