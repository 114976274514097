import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

const SEO = ({ description, meta, title }) => {
  const defaults = {
    description:
      'Le parole del lavoro e quelle che lavorano. Qui, dove linguaggio e lavoro si incontrano, grazie al lavorìo costante delle parole.',
    title: 'Parole al Lavoro',
    author: 'Anna Girardi',
  }
  const metaDescription = description || defaults.description

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${defaults.title}`}
      meta={[
        {
          name: `author`,
          content: defaults.author,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: [],
        },
        {
          property: `og:title`,
          content: `${title} | ${defaults.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@_annagirardi`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${defaults.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
